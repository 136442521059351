import * as React from 'react'
import Seo from '../components/seo'

const Page404 = () => {
  return (
    <>
      <Seo
        title={`Страница для очень любопытных людей`}
      />
      <p>Вариант, конечно интересный, но тут ничего нет, кроме текста который вы читаете. А вот в меню есть!</p>
    </>
  )
}

export default Page404
